import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MainLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Email = makeShortcode("Email");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Contacts`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Eredi di Monticelli Angelo`}<br />{`
di Sarnataro Emanuele e Lucio s.n.c.`}<br /></p>
        <p parentName="li">{`Via XXV Aprile, 11-13`}<br />{`
23819 Primaluna (LC)`}<br />{`
ITALY`}</p>
        <p parentName="li">{`Tel  +39 0341 980211`}<br />{`
Fax +39 0341 983780`}<br />{`
e-mail: `}<Email mdxType="Email" /></p>
        <p parentName="li">{`VAT ID: 01541490130`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      